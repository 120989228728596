import cloneDeep from "lodash.clonedeep";
import { TGenericData } from "../../../interfaces";
import {
  STORAGE_KEY_SEPARATOR,
  STORAGE_KEY_ARRAY_INDICATOR,
  STORAGE_KEY_OBJECT_INDICATOR,
} from "../../../constants";
import { ISetDynamicData } from "../store/PCPSlice";

/**
 * Sets the storage data in the given original state based on the provided storage key and data.
 *
 * Array Indicator:
 * If the storage key has an array indicator .[] , the data will be pushed to the array.
 * Examples:
 * 1) Update array:
 *    storageKey: "persons.sites.[]", data: "YES"
 *    { persons: { sites: [1, 2, 3] } } => { persons: { sites: [ 1, 2, 3, 'YES' ] } }
 * 2) Override array:
 *    storageKey: "persons.sites", data: [11, 22, 33]
 *    { persons: { sites: [1, 2, 3] } } => { persons: { sites: [ 11, 22, 33 ] } }
 *
 * Object Indicator:
 * If the storage key has an object indicator .{} , the data will be merged into the object.
 * Examples:
 * 1) Update object:
 *    storageKey: "persons.sites.{}", data: { key: "value" }
 *    { persons: { sites: { a: 1, b: 2 } } } => { persons: { sites: { a: 1, b: 2, key: "value" } } }
 * 2) Override object:
 *    storageKey: "persons.sites", data: { key: "value" }
 *    { persons: { sites: { a: 1, b: 2 } } } => { persons: { sites: { key: "value" } } }
 * 3) Merge data into the current state using notation {} :
 *    storageKey: "{}", data: { key: "value" }
 *    { a: 1, b: 2 } => { a: 1, b: 2, key: "value" }
 *
 * Direct Assignment:
 * If the storage key is an object, the data will be set as the value of the key.
 * Examples:
 * 1) storageKey: "persons.isSiteAvailable", data: "YES"
 *    { persons: { sites: [1, 2, 3] } } => { persons: { sites: [ 1, 2, 3 ], isSiteAvailable: 'YES' } }
 * 2) storageKey: "persons.sites", data: "YES"
 *    object: // { persons: { sites: { 0: 1, 1: 2, 2: 3 } } } => { persons: { sites: 'YES' } }
 *    string: // { persons: { sites: 'NO' } } => { persons: { sites: 'YES' } }
 *
 * Returns the updated state.
 *
 * @param originalState - The original state object.
 * @param storageKey - The key used to access the storage data.
 * @param data - The data to be stored.
 * @returns The updated state object.
 */

export const setStorageData = <T>(
  originalState: TGenericData,
  { storageKey, data }: ISetDynamicData,
): T => {
  const state = cloneDeep(originalState);
  const pathArr = storageKey.split(STORAGE_KEY_SEPARATOR);
  const lastKeyIndex = pathArr.length - 1;

  pathArr.reduce(
    (currentState: TGenericData, key: string, index: number): TGenericData => {
      if (key === STORAGE_KEY_ARRAY_INDICATOR && index === lastKeyIndex) {
        if (Array.isArray(currentState)) {
          // data can be an array or a single value
          const itemsToPush = Array.isArray(data) ? data : [data];
          currentState.push(...itemsToPush);
        }

        return currentState;
      }

      if (key === STORAGE_KEY_OBJECT_INDICATOR && index === lastKeyIndex) {
        if (typeof currentState === "object" && !Array.isArray(currentState)) {
          // data can be an object or a single value
          const itemsToMerge =
            typeof data === "object" ? data : { [index]: data };
          Object.assign(currentState, itemsToMerge);
        }

        return currentState;
      }

      // if we're at the last key (and it's not the array indicator or object indicator)
      if (index === lastKeyIndex) {
        // set the data at the key in the current state
        currentState[key] = data;
      } else if (!currentState[key]) {
        switch (pathArr[index + 1]) {
          case STORAGE_KEY_ARRAY_INDICATOR:
            currentState[key] = [];
            break;
          case STORAGE_KEY_OBJECT_INDICATOR:
            currentState[key] = {};
            break;
          default:
            currentState[key] = {};
            break;
        }
      }

      // go deeper into the state object
      return currentState[key] as TGenericData;
    },
    state,
  );

  return state as T;
};
